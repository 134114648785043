import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { newTerminalActions } from '../../../../../entities/new-terminal/model/slice/new-terminal-slice';
import { terminalActions } from '../../../../../entities/terminal/model/slices/terminal-slice';
import { 
  ResolutionString, 
  HistoryCallback, 
  ErrorCallback, 
  PeriodParams, 
} from '../../charting_library/datafeed-api';
import { getBars } from '../../helpers/get-bars';
import { ExtendedLibrarySymbolInfo } from '../types/chart-view.types';
import { normalizeExchangeName, resolutionToExchangeInterval } from '../utils/chart-utils';
import { CreateDataFeedParams } from './datafeed-factory';

export const createBarsMethods = ({
  exchangeName,
  currentSymbol,
  dispatch,
  isFuturesExchange,
}: CreateDataFeedParams) => ({
  getBars: async (
    symbolInfo: ExtendedLibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    HistoryCallback: HistoryCallback,
    onErrorCallback: ErrorCallback,
  ): Promise<void> => {
    try {
      console.log('Запрос баров:', {
        symbol: symbolInfo.ticker,
        resolution,
        from: new Date(periodParams.from * 1000).toISOString(),
        to: new Date(periodParams.to * 1000).toISOString(),
        countBack: periodParams.countBack,
        firstDataRequest: periodParams.firstDataRequest,
      });

      let effectiveLimit = periodParams.countBack;

      if (normalizeExchangeName(exchangeName) === EXCHANGE_NAMES.HTX) {
        // Инициализация накопленного лимита для HTX
        if (!window.htxAccumulatedLimit) {
          window.htxAccumulatedLimit = {};
        }
        if (periodParams.firstDataRequest) {
          window.htxAccumulatedLimit[symbolInfo.full_name] = periodParams.countBack;
        } else {
          // Увеличиваем лимит, только если текущий меньше 2000
          if (window.htxAccumulatedLimit[symbolInfo.full_name] < 2000) {
            window.htxAccumulatedLimit[symbolInfo.full_name] = Math.min(
              window.htxAccumulatedLimit[symbolInfo.full_name] + periodParams.countBack,
              2000,
            );
          }
        }
        effectiveLimit = window.htxAccumulatedLimit[symbolInfo.full_name];
      }

      const dataForFetch = {
        cryptoCompareSymbol: symbolInfo.pro_name,
        symbol: symbolInfo.full_name,
        fromDate: periodParams.from * 1000,
        toDate: periodParams.to * 1000,
        chartInterval: resolutionToExchangeInterval(resolution, exchangeName),
        limit: effectiveLimit,
        exchange: normalizeExchangeName(exchangeName),
        isFuturesExchange,
      };

      let bars = [];
      try {
        bars = await getBars(dataForFetch);
      } catch (error) {
        console.error('Error fetching bars:', error);
        HistoryCallback([], {
          noData: true, 
        });
        return;
      }

      if (bars.length === 0) {
        console.log('Нет данных для периода');
        HistoryCallback([], {
          noData: true, 
        });
        return;
      }

      const filteredBars = bars
        .filter(bar => {
          const barTime = Math.floor(bar.time / 1000);
          return barTime >= periodParams.from && barTime <= periodParams.to;
        })
        .sort((a, b) => a.time - b.time);

      const periodInMs = (() => {
        const resolutionNumber = parseInt(resolution);
        if (isNaN(resolutionNumber)) {
          switch (resolution) {
          case '1D': return 6 * 24 * 60 * 60 * 1000;
          case '1W': return 6 * 7 * 24 * 60 * 60 * 1000;
          default: return 6 * 60 * 60 * 1000;
          }
        }
        return 6 * resolutionNumber * 60 * 1000;
      })();

      if (periodParams.firstDataRequest) {
        const lastPrice = filteredBars[filteredBars.length - 1]?.close;
        if (lastPrice) {
          dispatch(terminalActions.setChartLastPrice(lastPrice));
          dispatch(terminalActions.setBaseOrderPrice(lastPrice));
          dispatch(newTerminalActions.setCurrentSymbol(currentSymbol));
          dispatch(newTerminalActions.setChartLastPrice(lastPrice));
        }
      }

      const isBitmart = normalizeExchangeName(exchangeName) === 'bitmart';
      const maxBitmartLimit = 200;
      const receivedFullLimit = bars.length === maxBitmartLimit;
      const hasEnoughValidBars = filteredBars.length >= periodParams.countBack;

      const noData =
        isBitmart
          ? !receivedFullLimit && hasEnoughValidBars
          : filteredBars.length < periodParams.countBack;

      let nextTime;
      // Если для HTX достигли максимального лимита, не инициируем дополнительные запросы
      if (normalizeExchangeName(exchangeName) === EXCHANGE_NAMES.HTX && effectiveLimit === 2000) {
        nextTime = undefined;
      } else if ((!noData || !hasEnoughValidBars) && bars.length > 0) {
        nextTime =
          filteredBars.length > 0
            ? filteredBars[0].time - periodInMs
            : Math.min(...bars.map(bar => bar.time)) - periodInMs;
      }

      console.log('Отправка ответа в TradingView:', {
        barsCount: filteredBars.length,
        originalBarsCount: bars.length,
        requiredBars: periodParams.countBack,
        noData,
        hasEnoughValidBars,
        receivedFullLimit,
        nextTime: nextTime ? new Date(nextTime).toISOString() : undefined,
        period: `${resolution} (${periodInMs}ms)`,
      });

      HistoryCallback(filteredBars, {
        noData,
        nextTime,
      });
    } catch (error) {
      console.error('Error in getBars:', error);
      onErrorCallback(error);
    }
  },
});
