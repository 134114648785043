import { getUserAuthenticated } from 'entities/user/model/selectors/get-user-authenticated/get-user-authenticated';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes, AuthRoutes } from 'shared/config/route-config';
import { appPath } from 'shared/config/route-config/app-routes/consts';

const AppRouter = () => {
  const isAuthorized = useSelector(getUserAuthenticated);

  const getRoutes = () => {
    if (isAuthorized) {
      return AppRoutes();
    }

    return AuthRoutes();
  };

  return (
    <Routes>
      <Route
        path='/'
        element={(
          <Navigate to={appPath.home.path} />
        )}
      />

      {getRoutes()}
    </Routes>
  );
};

export default memo(AppRouter);
