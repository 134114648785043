import dayjs from 'dayjs';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

export const useTrack = () => {
  const handleRemainingTime = () => {
    if (localStorage.getItem('remainingTime')) {
      return;
    }
    
    localStorage.setItem('remainingTime', dayjs().toString());
  };

  useEffect(() => {
    handleRemainingTime();

    const interval = setInterval(() => {
      const time = localStorage.getItem('remainingTime');
      const diff = dayjs().diff(dayjs(time), 'seconds');
      if (diff > 1800) {
        clearInterval(interval);
      }

      switch (diff) {
      case 60:
      case 180:
      case 300:
      case 600:
      case 900:
      case 1800:
        ReactPixel.trackCustom(`Session_${diff / 60}min`);
        break;
      default:
        break;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
};
