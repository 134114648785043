import dayjs from 'dayjs';
import { botGetPublicDeals, getBotPublicStatistic } from 'entities/bot/api';
import { ActiveDeal, Bot } from 'entities/bot/model/types';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { useCallback, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { defaultDates } from 'shared/consts';
import { getExchangeAccountsUuids } from 'shared/helpers';
import { useExcahnges } from 'shared/hooks';
import { TDates } from 'shared/types';
import { createNewCopyBot } from '../api/create-copy-bot';
import { createCopyBotSubscription } from '../api/create-copy-bot-subscription';
import { getBotsAccounts, updateBotsAccounts } from '../helpers';

interface IUseCopyBotParams {
  bot: Bot;
  bots: Bot[];
}
export const useCopyBot = (params: IUseCopyBotParams) => {
  const {
    bot,
    bots,
  } = params;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTradingHistoryLoading, setIsTradingHistoryLoading] = useState<boolean>(true);
  const [isStatisticLoading, setIsStatisticLoading] = useState<boolean>(true);
  const [updatedExchanges, setUpdatedExchanges] = useState<ExchangeAccount[]>([]);
  const [dates, setDates] = useState<TDates>([null, null]);
  const [tradingHistoryDates, setTradingHistoryDates] = useState<TDates>(defaultDates);
  const [selectedExchange, setSelectedExchange] = useState<string>('');
  const [botTrades, setBotTrades] = useState<ActiveDeal[]>([]);
  const [selectedPeriodOption, setSelectedPeriodOption] = useState<string>('summary');
  const [statistic, setStatistic] = useState<any>(null);

  const {
    values: {
      exchanges,
    },
    handlers,
  } = useExcahnges();

  const navigate = useNavigate();

  const changeDates = (dates: TDates) => {
    setDates(dates);
  };

  const changeTradingHistoryDates = (dates: TDates) => {
    setTradingHistoryDates(dates);
  };

  const changeExchange = (selected: string) => {
    setSelectedExchange(selected);
  };

  const changePeriod = (value: string) => {
    setSelectedPeriodOption(value);
  };

  const getSelectedAccount = () => {
    return updatedExchanges.find((exchange) => {
      return exchange.exchangeAccountUuid === selectedExchange;
    });
  };

  const getAvailableAmount = () => {
    const account = getSelectedAccount();
    
    if (!account) {
      return 0;
    }

    const balance = account.balances?.find((balance: any) => {
      return balance.symbol === bot.quote;
    })?.free;

    return +(balance || 0);
  };

  const getBot = () => {
    const selectedAccount = getSelectedAccount();

    const existedBot = bots.find((bot) => {
      return bot.exchangeCode === selectedAccount?.exchangeCode;
    });

    return existedBot || bot;
  };

  const getCurrentStatistic = () => {
    return (statistic || bot.statistics);
  };

  const getChartData = () => {
    if (!statistic) {
      return [];
    }

    const defaultData = getCurrentStatistic();

    const preffix = (selectedPeriodOption === 'summary' ? 'sum' : selectedPeriodOption).toLowerCase();
    const key = `${preffix}ChartData`;

    const currentStatisticsData = (defaultData[key] as any[]).map((data): [string, number] => {
      if (selectedPeriodOption === 'day' || selectedPeriodOption === 'summary') {
        return [dayjs(data.date).format('DD-MM-YY'), (data.return / defaultData.totalPnl) * defaultData.roi];
      }
    
      return [data.pair, (data.return / defaultData.totalPnl) * defaultData.roi];
    });
    
    return currentStatisticsData;
  };

  const exchangeOptions = useCallback(() => {
    const options = updatedExchanges?.map((account: ExchangeAccount) => ({
      label: `${account.accountName}_${account.exchangeCode}_${account.totalUsdt || 0}`,
      value: account.exchangeAccountUuid,
    }));

    return options;
  }, [updatedExchanges]);

  const createCopyBot = async (values: Record<'exchange' | 'name' | 'balance', string>) => {
    try {
      const bot = getBot();

      if (!bot) {
        return;
      }
      
      const responseFromCreateCopyBot = await createNewCopyBot({
        tradingAmount: values.balance,
        botUuid: bot.botUuid,
        exchangeCode: bot.exchangeCode,
        exchangeAccountUuid: values.exchange,
        botName: values.name, 
      });

      if (!responseFromCreateCopyBot.success) {
        toast.error(responseFromCreateCopyBot.data.message);
        return;
      }

      ReactPixel.trackCustom('CreateCopyBot');
      
      const responseFromCreateBotSuccessSubscription = await createCopyBotSubscription({
        sourceUuid: responseFromCreateCopyBot.data.fields.sourceUuid,
        botUuid: responseFromCreateCopyBot.data.fields.botUuid,
      });
      
      if (!responseFromCreateBotSuccessSubscription.success) {
        toast.error(responseFromCreateBotSuccessSubscription.data.message);
        return;
      }

      const createdCopyBotUuid = responseFromCreateCopyBot.data.fields.botUuid;
      navigate(appPath.trading_bots_my_bot.navigate(createdCopyBotUuid));
    } catch (error) {
      
    } finally {
      
    }
  };

  const getBotDealsData = async () => {
    try {
      setIsTradingHistoryLoading(true);
      const currentExchangeCodeBot = getBot();
      const botDeals = await botGetPublicDeals({
        botUuid: (currentExchangeCodeBot?.botUuid || bot.botUuid),
        tradingHistoryDates,
      });
      setBotTrades(botDeals);
    } finally {
      setIsTradingHistoryLoading(false);
    }
  };

  const fetchBotStatistic = async () => {
    try {
      setIsStatisticLoading(true);
      const existBot = getBot();
      const statistic = await getBotPublicStatistic({
        botUuid: existBot?.botUuid,
        dates,
      });
      setStatistic(statistic);
    } finally {
      setIsStatisticLoading(false);
    }
  };
  
  const fetchCopyBot = async () => {
    try {
      setIsLoading(true);

      const accounts = getBotsAccounts({
        exchanges: exchanges!,
        bots,
      });
      
      const balances = await handlers.fetchExchangesBalances(getExchangeAccountsUuids(accounts));

      const updatedAccounts = updateBotsAccounts({
        accounts,
        balances,
      });
      
      setUpdatedExchanges(updatedAccounts);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges) {
      return;
    }
    
    if (!exchanges.length) {
      return;
    }

    fetchCopyBot();
  }, [exchanges]);

  useEffect(() => {
    getBotDealsData();
  }, [selectedExchange, tradingHistoryDates]);

  useEffect(() => {
    fetchBotStatistic();
  }, [selectedExchange, dates]);

  return {
    values: {
      isLoading,
      isTradingHistoryLoading,
      updatedExchanges,
      dates,
      tradingHistoryDates,
      selectedExchange,
      exchangeOptions,
      botTrades,
      selectedPeriodOption,
      statistic: getCurrentStatistic(),
      exchangeCode: getSelectedAccount()?.exchangeCode,
      isStatisticLoading,
    },
    handlers: {
      setIsLoading,
      changeDates,
      changeTradingHistoryDates,
      getAvailableAmount,
      changeExchange,
      changePeriod,
      getChartData,
      createCopyBot,
    },
  };
};
