import axios from 'axios';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { ENV } from 'shared/config/environment';
import { 
  BinanceSymbol, 
  BybitSymbol, 
  OkxSymbol, 
  GateIOSymbol, 
  CryptoComSymbol, 
  BitMartSymbol,
  HtxSymbol,
  AdaptedSymbol,
  BaseExchangeSymbol,
  adaptBinanceSymbol,
  adaptBybitSymbol,
  adaptCryptoComSymbol,
  adaptGateIOSymbol,
  adaptOkxSymbol,
  adaptBitMartSymbol,
  adaptHtxSymbol,
  adaptCryptoCompareSymbol,
  CryptoCompareSymbol,
  KucoinSymbol,
  adaptKucoinSymbol,
} from './adapt-exchange-symbol';

type ExchangeSymbolType = 
  | BinanceSymbol
  | BybitSymbol 
  | OkxSymbol 
  | GateIOSymbol 
  | CryptoComSymbol 
  | BitMartSymbol
  | HtxSymbol
  | CryptoCompareSymbol
  | KucoinSymbol;

interface ExchangeConfig<T extends ExchangeSymbolType> {
    url: string;
    dataPath: string[];
    filterPredicate: (symbol: BaseExchangeSymbol) => boolean;
    adapter: (symbol: T) => AdaptedSymbol;
  }

  type ExchangeConfigs = {
    binance: ExchangeConfig<BinanceSymbol>;
    bybit: ExchangeConfig<BybitSymbol>;
    bybitFutures: ExchangeConfig<BybitSymbol>;
    okx: ExchangeConfig<OkxSymbol>;
    gateio: ExchangeConfig<GateIOSymbol>;
    cryptocom: ExchangeConfig<CryptoComSymbol>;
    bitmart: ExchangeConfig<BitMartSymbol>;
    htx: ExchangeConfig<HtxSymbol>;
    cryptoCompare: ExchangeConfig<CryptoCompareSymbol>;
    kucoin: ExchangeConfig<KucoinSymbol>;
  };

const CORS_PROXY = ENV['ENV'] === 'development' ? 'https://cors-anywhere.herokuapp.com/' : '';
const gateioUrl = `${CORS_PROXY}${ENV['ENV'] === 'development' 
  ? 'https://api.gateio.ws'
  : `${window.location.origin}/gateio-api`}/api/v4/spot/currency_pairs`;
const kucoinUrl = `${CORS_PROXY}${ENV['ENV'] === 'development' 
  ? 'https://api.kucoin.com'
  : `${window.location.origin}/kucoin-api`}/api/v1/symbols`;

const CRYPTO_COMPARE_EXCHANGE_MAP: Record<string, string> = {
  binance: 'binance',
  bybit: 'bybit',
  okx: 'okex',
  gateio: 'gateio',
  cryptocom: 'cryptodotcom',
  bitmart: 'bitmart',
  htx: 'huobipro',
  kucoin: 'kucoin',
};  
  
const EXCHANGE_CONFIGS: ExchangeConfigs = {
  binance: {
    url: 'https://api.binance.com/api/v3/exchangeInfo',
    dataPath: ['symbols'],
    filterPredicate: (symbol) => symbol.status === 'TRADING',
    adapter: adaptBinanceSymbol,
  },
  bybit: {
    url: 'https://api.bybit.com/v5/market/instruments-info?category=spot',
    dataPath: ['result', 'list'],
    filterPredicate: (symbol) => symbol.status === 'Trading',
    adapter: adaptBybitSymbol,
  },
  bybitFutures: {
    url: 'https://api.bybit.com/v5/market/instruments-info?category=linear',
    dataPath: ['result', 'list'],
    filterPredicate: (symbol) => symbol.status === 'Trading',
    adapter: adaptBybitSymbol,
  },
  okx: {
    url: 'https://www.okx.com/api/v5/public/instruments?instType=SPOT',
    dataPath: ['data'],
    filterPredicate: (symbol) => symbol.state === 'live',
    adapter: adaptOkxSymbol,
  },
  gateio: {
    url: gateioUrl,
    dataPath: [],
    filterPredicate: (symbol) => symbol.trade_status === 'tradable',
    adapter: adaptGateIOSymbol,
  },
  cryptocom: {
    url: 'https://api.crypto.com/exchange/v1/public/get-instruments',
    dataPath: ['result', 'data'],
    filterPredicate: (symbol) => symbol.inst_type === 'CCY_PAIR' && symbol.tradable === true,
    adapter: adaptCryptoComSymbol,
  },
  bitmart: {
    url: 'https://api-cloud.bitmart.com/spot/v1/symbols/details',
    dataPath: ['data', 'symbols'],
    filterPredicate: (symbol) => symbol.trade_status === 'trading',
    adapter: adaptBitMartSymbol,
  },
  htx: {
    url: 'https://api.huobi.pro/v1/settings/common/market-symbols',
    dataPath: ['data'],
    filterPredicate: (symbol) => symbol.state === 'online',
    adapter: adaptHtxSymbol,
  },
  kucoin: {
    url: kucoinUrl,
    dataPath: ['data'],
    filterPredicate: (symbol) => symbol.enableTrading === true,
    adapter: adaptKucoinSymbol,
  },
  cryptoCompare: {
    url: 'https://data-api.cryptocompare.com/spot/v1/markets/instruments',
    dataPath: ['Data'],
    //@ts-ignore
    filterPredicate: (symbol) => console.log('symbol', symbol),
    adapter: adaptCryptoCompareSymbol,
  },
};

const fetchExchangeData = async (url: string, exchangeKey?: string) => {
  try {
    console.log(`Attempting to fetch data from: ${url}`);
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching exchange data: ${error}`);
    
    if (exchangeKey) {
      try {
        console.log(`🔄 Fallback: Attempting to fetch from CryptoCompare for ${exchangeKey}`);
        
        
        const market = CRYPTO_COMPARE_EXCHANGE_MAP[exchangeKey.toLowerCase()];
        if (!market) {
          console.error(`❌ Unsupported exchangeKey "${exchangeKey}" for CryptoCompare mapping`);
          return null;
        }
        
        const response = await axios.get(
          'https://data-api.cryptocompare.com/spot/v1/markets/instruments',
          {
            params: {
              market,
              response_format: 'JSON',
            },
            headers: ENV.CRYPTOCOMPARE_API_KEY 
              ? {
                'Authorization': `Apikey ${ENV.CRYPTOCOMPARE_API_KEY}`, 
              }
              : {},
          },
        );

        console.log(`✅ Successfully received data from CryptoCompare for ${exchangeKey}`);
        return response.data;
      } catch (fallbackError) {
        console.error('❌ CryptoCompare fallback failed:', fallbackError);
      }
    }
    return null;
  }
};

const getDataByPath = (data: any, paths: string[]): any => {
  return paths.reduce((acc, path) => (acc ? acc[path] : acc), data);
};

const fetchExchangeInfo = async <T extends keyof ExchangeConfigs>(
  exchangeKey: T,
): Promise<CurrentSymbol[]> => {
  try {
    const config = EXCHANGE_CONFIGS[exchangeKey];
    const data = await fetchExchangeData(config.url, exchangeKey);
    
    if (!data) {
      console.error(`Failed to fetch data for ${exchangeKey}`);
      return [];
    }

    if (data.Data) {
      // Шаг 1: Получаем данные конкретной биржи
      const exchangeData = data.Data[exchangeKey.toLowerCase()];
      if (!exchangeData) {
        console.error(`No data for ${exchangeKey} in CryptoCompare response`);
        return [];
      }

      // Шаг 2: Получаем объект с инструментами
      const instruments = exchangeData.instruments;
      if (!instruments) {
        console.error(`No instruments for ${exchangeKey} in CryptoCompare response`);
        return [];
      }

      const instrumentsArray = Object.entries(instruments).map(([key, value]) => ({
        //@ts-ignore
        ...value,
        element: key,
      }));
      
      return instrumentsArray.map(EXCHANGE_CONFIGS.cryptoCompare.adapter) as unknown as CurrentSymbol[];
    }
    
    // Обработка обычного ответа от биржи
    const symbols = config.dataPath.length > 0 
      ? getDataByPath(data, config.dataPath)
      : data;
      
    return symbols
      .filter(config.filterPredicate)
      .map(config.adapter);
  } catch (error) {
    console.error(`Error processing ${exchangeKey} exchange data:`, error);
    return [];
  }
};

export const fetchBinanceExchangeInfo = () => fetchExchangeInfo('binance');
export function fetchBybitExchangeInfo(isFutures = false) {
  // Если фьючерсы – используем ключ 'bybitFutures'
  const exchangeKey = isFutures ? 'bybitFutures' : 'bybit';

  return fetchExchangeInfo(exchangeKey);
}
export const fetchOkxExchangeInfo = () => fetchExchangeInfo('okx');
export const fetchGateIOExchangeInfo = () => fetchExchangeInfo('gateio');
export const fetchCryptoComExchangeInfo = () => fetchExchangeInfo('cryptocom');
export const fetchBitmartExchangeInfo = () => fetchExchangeInfo('bitmart');
export const fetchHtxExchangeInfo = () => fetchExchangeInfo('htx');
export const fetchKucoinExchangeInfo = () => fetchExchangeInfo('kucoin');
