import { getPrecisionNumber, sanitizeSymbol, sanitizeSymbolCryptoCom, sanitizeSymbolGate } from 'pages/trading-terminal-page/trading-chart/helpers/common-utils';

interface BaseExchangeSymbol {
    symbol: string;
    status?: string;
    state?: string;
    trade_status?: string;
    inst_type?: string;
    tradable?: boolean;
    enableTrading?: boolean;
  }
  
  interface BinanceSymbol extends BaseExchangeSymbol {
    baseAsset: string;
    quoteAsset: string;
    quoteAssetPrecision: number;
    filters: Array<{
      filterType: string;
      tickSize?: string;
      stepSize?: string;
      minPrice?: string;
      maxPrice?: string;
      minQty?: string;
      maxQty?: string;
      minNotional?: string;
      maxNotional?: string;
    }>;
  }
  
  interface BybitSymbol extends BaseExchangeSymbol {
    baseCoin: string;
    quoteCoin: string;
    lotSizeFilter: {
      basePrecision: string;
      quotePrecision: string;
      minOrderQty: string;
      maxOrderQty: string;
      minOrderAmt: string;
      maxOrderAmt: string;
      minNotionalValue: string;
    };
    priceFilter: {
      tickSize: string;
    };
  }
  
  interface OkxSymbol extends BaseExchangeSymbol {
    instId: string;
    baseCcy: string;
    quoteCcy: string;
    lotSz: string;
    tickSz: string;
    minSz: string;
    maxLmtSz: string;
    maxLmtAmt: string;
  }
  
  interface GateIOSymbol extends BaseExchangeSymbol {
    id: string;
    base: string;
    quote: string;
    min_quote_amount: string;
    max_quote_amount: string;
    min_base_amount: string;
    max_base_amount: string;
    amount_precision: number;
    precision: number;
  }
  
  interface CryptoComSymbol extends BaseExchangeSymbol {
    quote_ccy: string;
    quote_decimals: number;
    price_tick_size: string;
    qty_tick_size: string;
    quantity_decimals: number;
    base_ccy: string;
  }
  
  interface BitMartSymbol extends BaseExchangeSymbol {
    quote_currency: string;
    min_buy_amount: number;
    price_max_precision: string;
    quote_increment: string;
    base_currency: string;
    base_min_size: string;
  }
  
  interface HtxSymbol extends BaseExchangeSymbol {
    symbol: string;
    bc: string;
    qc: string;
    pp: number;
    ap: number;
    vp: number;
    minoa: number;
    maxoa: number;
    minov: number;
    smmaxoa: number;
    bmmaxov: number;
    
// - "symbol": "btcusdt" - символ
// - "bc": "btc" - бейз каренси
// - "qc": "usdt" - маркет (квот карренси)
// - "state": "online" - статус пары нас интересуют только "online"
// - "pp": 2 - минимальное изменение цены на графике (знаков после запятой) (chartPrecision или tickSize)
// - "ap": 6 - минимальное изменение units (знаков после запятой) (stepSize)
// - "vp": 8 - минимальное изменение total (знаков после запятой)
// - "minoa": 0.0001 - минимальный размер ордера по Units
// - "maxoa": 1000 - максимальный размер ордера по Units
// - "minov": 10 - минимальный размер ордера по Total (minNotional)
// - "smmaxoa": 5 - максимальный размер SELL market ордера по Units
// - "bmmaxov": 50000 - максимальный размер BUY market ордера по Total
  }
  
  interface CryptoCompareSymbol extends BaseExchangeSymbol {
    INSTRUMENT: string;
    INSTRUMENT_MAPPING: {
      BASE: string;
      QUOTE: string;
    };
    MAPPED_INSTRUMENT: string;
  }
  
  interface KucoinSymbol extends BaseExchangeSymbol {
    symbol: string;
    name: string;
    baseCurrency: string;
    quoteCurrency: string;
    baseMinSize: string;
    baseMaxSize: string;
    quoteMinSize: string;
    quoteMaxSize: string;
    baseIncrement: string;
    quoteIncrement: string;
    priceIncrement: string;
    feeCurrency: string;
    enableTrading: boolean;
    isMarginEnabled: boolean;
    priceLimitRate: string;
    minFunds: string;
    market: string;
  }
  
  // Общий интерфейс для адаптированных данных
  interface AdaptedSymbol {
    symbol: string;
    baseAsset: string;
    quoteAsset: string;
    baseAssetPrecision: number;
    quoteAssetPrecision: number;
    // округление для цены на графике и orderPrice в панели и сделках
    chartPrecision: number;
    minNotional: string | number;
    maxNotional: string | number;
    lotMax: string | number;
    lotMin: string | number;
    priceMin: string | number;
    priceMax: string | number;
    [key: string]: any; // для дополнительных специфичных полей
  }
  
// Адаптеры
export function adaptBinanceSymbol(symbol: BinanceSymbol): AdaptedSymbol {
  const priceFilter = symbol.filters.find(el => el.filterType === 'PRICE_FILTER');
  const lotFilter = symbol.filters.find(el => el.filterType === 'LOT_SIZE');
  const notionalFilter = symbol.filters.find(el => el.filterType === 'NOTIONAL');
  const marketLotFilter = symbol.filters?.find(el => el.filterType === 'MARKET_LOT_SIZE');
  
  //@TODO check for Exchange Props, delete unnecessary
  
  return {
    symbol: symbol.symbol,
    tvFormatSymbol: `${symbol.baseAsset}${symbol.quoteAsset}`,
    baseAsset: symbol.baseAsset,
    baseAssetPrecision: getPrecisionNumber(lotFilter?.stepSize || '0'),
    quoteAsset: symbol.quoteAsset,
    quoteAssetPrecision: symbol.quoteAssetPrecision,
    priceMin: priceFilter?.minPrice || '0.00000001',
    priceMax: priceFilter?.maxPrice || '999999999',
    minNotional: notionalFilter?.minNotional || '0.00000001',
    maxNotional: notionalFilter?.maxNotional || '999999999',
    lotMax: lotFilter?.maxQty || '99999999',
    lotMin: lotFilter?.minQty || '99999999',
    marketLotMax: marketLotFilter?.maxQty || '99999999',
    marketLotMin: marketLotFilter?.minQty || '99999999',
    chartPrecision: getPrecisionNumber(priceFilter?.tickSize || '0'),
  };
}
  
export function adaptBybitSymbol(symbol: BybitSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol,
    symbol: symbol.symbol,
    baseAsset: symbol.baseCoin,
    baseAssetPrecision: getPrecisionNumber(symbol.lotSizeFilter.basePrecision) || 6,
    quoteAsset: symbol.quoteCoin,
    quoteAssetPrecision: getPrecisionNumber(symbol.lotSizeFilter.quotePrecision) || 6,
    priceMin: '0.00000001',
    priceMax: '999999999',
    minNotional: symbol.lotSizeFilter.minOrderAmt || symbol.lotSizeFilter.minNotionalValue,
    maxNotional: symbol.lotSizeFilter.maxOrderAmt || '999999999',
    lotMax: symbol.lotSizeFilter.maxOrderQty,
    lotMin: symbol.lotSizeFilter.minOrderQty,
    //округелние для квота
    chartPrecision: getPrecisionNumber(symbol.priceFilter.tickSize),
  };
}

export function adaptOkxSymbol(symbol: OkxSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.instId,
    okxSymbol: symbol.instId,
    symbol: sanitizeSymbol(symbol.instId),
    baseAsset: symbol.baseCcy,
    quoteAsset: symbol.quoteCcy,
    baseAssetPrecision: getPrecisionNumber(symbol.lotSz),
    quoteAssetPrecision: getPrecisionNumber(symbol.tickSz),
    priceMin: '0.00000001',
    priceMax: '999999999',
    minNotional: symbol.lotSz,
    maxNotional: symbol.maxLmtAmt,
    lotMin: symbol.minSz,
    lotMax: symbol.maxLmtSz,
    chartPrecision: getPrecisionNumber(symbol.tickSz),
  };
}

export function adaptGateIOSymbol(symbol: GateIOSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.id,
    //@TODO check for sanitizing symbols
    symbol: sanitizeSymbolGate(symbol.id),
    gateSymbol: symbol.id,
    baseAsset: symbol.base,
    quoteAsset: symbol.quote,
    baseAssetPrecision: symbol.amount_precision,
    quoteAssetPrecision: symbol.precision,
    priceMin: '0',
    priceMax: '999999999',
    minNotional: symbol.min_quote_amount,
    maxNotional: symbol.max_quote_amount,
    lotMin: symbol.min_base_amount,
    lotMax: symbol.max_base_amount,
    chartPrecision: symbol.precision,
  };
}

export function adaptCryptoComSymbol(symbol: CryptoComSymbol): AdaptedSymbol {
  const minNotional = (Number(symbol.price_tick_size) * Number(symbol.qty_tick_size)).toFixed(8);

  return {
    tvFormatSymbol: symbol.symbol,
    symbol: sanitizeSymbolCryptoCom(symbol.symbol),
    cryptoComSymbol: symbol.symbol,
    baseAsset: symbol.base_ccy,
    quoteAsset: symbol.quote_ccy,
    baseAssetPrecision: symbol.quantity_decimals,
    quoteAssetPrecision: symbol.quote_decimals,
    chartPrecision: symbol.quote_decimals,
    minNotional: minNotional,
    maxNotional: '999999999',
    lotMax: '99999999',
    lotMin: '0.00000001',
    priceMin: '0.00000001',
    priceMax: '999999999',
  };
}

export function adaptBitMartSymbol(symbol: BitMartSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol,
    symbol: `${symbol.base_currency}${symbol.quote_currency}`,
    // symbol: symbol.symbol,
    baseAsset: symbol.base_currency,
    quoteAsset: symbol.quote_currency,
    baseAssetPrecision: getPrecisionNumber(symbol.base_min_size),
    quoteAssetPrecision: Number(symbol.price_max_precision),
    minNotional: symbol.min_buy_amount,
    maxNotional: '999999999',
    chartPrecision: Number(symbol.price_max_precision),
    lotMax: '99999999',
    lotMin: symbol.base_min_size,
    priceMin: '0.00000001',
    priceMax: '999999999',
  };
}

export function adaptHtxSymbol(symbol: HtxSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol.toUpperCase(),
    symbol: symbol.symbol.toUpperCase(),
    baseAsset: symbol.bc.toUpperCase(),
    quoteAsset: symbol.qc.toUpperCase(),
    chartPrecision: symbol.pp,
    baseAssetPrecision: symbol.ap,
    quoteAssetPrecision: symbol.vp,
    minNotional: symbol.minov,
    maxNotional: symbol.bmmaxov,
    lotMin: symbol.minoa,
    lotMax: symbol.maxoa,
    priceMin: '0.00000001',
    priceMax: '999999999',
  };
}

export function adaptKucoinSymbol(symbol: KucoinSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol,
    symbol: symbol.symbol,
    baseAsset: symbol.baseCurrency,
    quoteAsset: symbol.quoteCurrency,
    baseAssetPrecision: getPrecisionNumber(symbol.baseIncrement),
    quoteAssetPrecision: getPrecisionNumber(symbol.quoteIncrement),
    chartPrecision: getPrecisionNumber(symbol.priceIncrement),
    minNotional: symbol.quoteMinSize,
    maxNotional: symbol.quoteMaxSize,
    lotMin: symbol.baseMinSize,
    lotMax: symbol.baseMaxSize,
    priceMin: '0.00000001',
    priceMax: '999999999',
  };
}

export function adaptCryptoCompareSymbol (symbol: CryptoCompareSymbol): AdaptedSymbol {  
  return {
    tvFormatSymbol: symbol.INSTRUMENT_MAPPING.BASE + symbol.INSTRUMENT_MAPPING.QUOTE,
    symbol: symbol.INSTRUMENT_MAPPING.BASE + symbol.INSTRUMENT_MAPPING.QUOTE,
    baseAsset: symbol.INSTRUMENT_MAPPING.BASE,
    quoteAsset: symbol.INSTRUMENT_MAPPING.QUOTE,
    pro_name: symbol.MAPPED_INSTRUMENT,
    chartPrecision: 3,
    baseCurrencyChartPrecision: 8,
    quantityPrecision: 8,
    baseAssetPrecision: 8,
    quoteAssetPrecision: 8,
    minNotional: '0.00000001',
    maxNotional: '999999999',
    lotMax: '99999999',
    lotMin: '0.00000001',
    priceMin: '0.00000001',
    priceMax: '999999999',
  };
}

export type {
  BaseExchangeSymbol,
  BinanceSymbol,
  BybitSymbol,
  OkxSymbol,
  GateIOSymbol,
  CryptoComSymbol,
  BitMartSymbol,
  HtxSymbol,
  CryptoCompareSymbol,
  KucoinSymbol,
  AdaptedSymbol
};
