import { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { useQuery } from 'shared/hooks';
import { Loader } from 'shared/ui';
import { connectOauth } from '../api/connect-oauth';

let link = '';

function createOAuthComponent(accountName: string, exchangeCode: string, pathSegment: string) {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const query = useQuery();
    const [loading, setLoading] = useState<boolean>(true);

    const skyrexUserUuid = getSkyrexUuid();

    const handleSendOauth = async (skyrexUserUuid: string, userAccessToken: string) => {
      try {
        setLoading(true);

        const dataForOauth = {
          skyrexUserUuid,
          userAccessToken,
          accountName,
          exchangeCode: query.get('type') === 'futures' ? `${exchangeCode}-futures` : exchangeCode,
        };

        const connectionType = localStorage.getItem('connection_type');
        const prevLocation = localStorage.getItem('prev_location');
        
        if (connectionType === 'onboarding') {
          link += `${prevLocation}?mt=onboarding&status=success`;
        } else {
          link = prevLocation || '/my-accounts';
        }

        const oauthResponse = await connectOauth(dataForOauth);
        if (!oauthResponse.success) {
          navigate(link, {
            state: {
              success: false,
              message: oauthResponse?.data?.message,
            },
          });
          return;
        }

        ReactPixel.trackCustom('ConnectExchange');
        navigate(link, {
          state: {
            success: true,
          },
        });
        return;
      } catch (error) {
        navigate(link, {
          state: {
            success: false,
            message: 'We will fix it soon, try again later or contact support',
          },
        });
        return;
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      const {
        pathname, 
        search,
      } = location;
      
      if (pathname.includes(pathSegment)) {
        const params = new URLSearchParams(search);
        
        let code;
        if (pathSegment === '/oauth/create/htx') {
          const accessKey = params.get('accessKey');
          const secretKey = params.get('secretKey');
          const authorization = params.get('authorization');
          
          if (authorization !== '1') {
            navigate(link, {
              state: {
                success: false,
                message: 'HTX authorization failed, check account credentials and try again',
              },
            });
            setLoading(false);
            return;
          }
          
          code = `${accessKey}~${secretKey}`;
        } else {
          code = params.get('code');
        }
        
        handleSendOauth(skyrexUserUuid ?? '', code ?? '');
      }
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

function createOAuthCryptoComComponent() {
  return function OauthComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const skyrexUserUuid = getSkyrexUuid();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      console.log('createOAuthCryptoComComponent', location, skyrexUserUuid);
      navigate('/my-accounts?mt=crypto-connected');
      setLoading(false);
    }, [location, skyrexUserUuid]);

    if (loading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return null;
  };
}

export const OauthBinance = createOAuthComponent('My Binance', 'binance', '/oauth/create/binance');
export const OauthBybit = createOAuthComponent('My Bybit', 'bybit', '/oauth/create/bybit');
export const OauthOkx = createOAuthComponent('My OKX', 'okx', '/oauth/create/okx');
export const OauthGateio = createOAuthComponent('My Gateio', 'gateio', '/oauth/create/gateio');
export const OauthHtx = createOAuthComponent('My HTX', 'htx', '/oauth/create/htx');
export const OauthKucoin = createOAuthComponent('My Kucoin', 'kucoin', '/oauth/create/kucoin');
export const OauthCryptoCom = createOAuthCryptoComComponent();
