import { Stack } from '@mui/material';
import { Steps } from 'antd';
import { Preview } from 'pages/register/components';
import { useCallback, useMemo, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { secretToken, sourceUuid } from 'shared/consts';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Text } from 'shared/ui';
import { createBot, updateBot } from '../api';
import { BotName } from '../components';
import {
  defaultAdditionalStepValues,
  defaultAutoStepValues,
  defaultCloseStepValues,
  defaultFirstStepValues,
  defaultSecondStepValues,
  defaultSteps,
  strategyCustomSteps,
} from '../consts';
import { convertDirection } from '../helpers';
import { ICreateBot } from '../interfaces';
import { STEP, WRAPPER } from '../styles';
import { AlertMessage, ChooseAccount, ChooseSource, ConfigureRiskManagment } from '../views';

export const Configurator = () => {
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    id, 
  } = useParams();
  const {
    t, 
  } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      currentStep: 0,
      errors: [],
      ...defaultFirstStepValues(),
      ...defaultSecondStepValues(),
      auto: defaultAutoStepValues(),
      additional: defaultAdditionalStepValues(),
      close: defaultCloseStepValues(),
    },
  });

  const name = methods.watch('name');
  const currentStep = methods.watch('currentStep');
  const tradingAmount = methods.watch('tradingAmount');
  const exchange = methods.watch('exchange');
  const market = methods.watch('market');
  const activeTradingPairs = methods.watch('activeTradingPairs');
  const autoState = methods.watch('auto');
  const chooseSourceOrderType = methods.watch('orderType');
  const chooseSourceAlertSource = methods.watch('alertSource');
  const additionalEntryOrdersState = methods.watch('additional');
  const closeOrdesState = methods.watch('close');
  const futures = methods.watch('futures');
  const errors = methods.watch('errors');

  const token = useMemo(() => {
    if (!exchange.value.id) {
      return '';
    }
  
    const generatedToken = secretToken(exchange.value.id);
    return generatedToken;
  }, [exchange]);

  const createBotHandler = async () => {
    const isBaseOrderError = market.value.minNotional > (autoState.values.baseOrderSize || 0);
    if (autoState.segment === 'auto' ? isBaseOrderError : false) {
      toast.error('Order size is below the minimum');
      return;
    }

    const params: ICreateBot = {
      name: name as string,
      skyrexUserUuid: getSkyrexUuid() as string,
      exchangeAccountUuid: exchange.value.id,
      botType: 'CUSTOM',
      allowSharing: 'FALSE',
      exchangeCode: exchange.value.code,
      quote: market.value.code,
      minInvestment: '0',
      copyCommissionPercent: 0,
      copyCommissionFlat: 0,
      tradingAmount: +tradingAmount,
      maxActiveDeals: +activeTradingPairs,
      martingaleVolumeCoefficient: 1,
      maxSafetyOrders: 0,
      priceDeviationPercentSafety: 0,
      martingalePriceStepCoefficient: 0,
      strategy: 'LONG',
      baseOrderType: chooseSourceOrderType.toUpperCase(),
      baseOrderConditionalTriggerType: 'LAST',
      additionalBaseOrderType: null,
      additionalBaseOrderConditionalTriggerType: 'LAST',
      takeProfitPriceRecalculation: 'FROM_AVERAGE',
      takeProfitType: null,
      takeProfitConditionalTriggerType: 'LAST',
      takeProfitPricePercent: 0,
      takeProfitPriceStepCoefficient: 1,
      takeProfitTargetsQuantity: 0,
      takeProfitVolumeStepCoefficient: 1,
      stopLossPriceRecalculation: 'FROM_AVERAGE',
      stopLossType: null,
      stopLossConditionalTriggerType: 'LAST',
      stopLossPercent: 0,
      alertAdditionalBaseOrderType: null,
      alertCloseType: null,
      signalSource: chooseSourceAlertSource,
      secretToken: token,
      sourceUuid,
    };

    if (exchange.value.code.includes('futures')) {
      params.strategy = futures.strategy.toUpperCase() as ICreateBot['strategy'];
      params.leverage = futures.leverage;
      params.marginMode = futures.marginMode.toUpperCase();
    }

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'preset') {
      const preset = additionalEntryOrdersState.segment.preset;

      params.additionalBaseOrderType = `CONDITIONAL_${preset.orderType.toUpperCase()}`;
      params.martingaleVolumeCoefficient = preset.ordersSizeScale;
      params.maxSafetyOrders = preset.orders;
      params.priceDeviationPercentSafety = convertDirection({
        value: preset.priceChange,
        stratage: 'LONG',
        orderType: 'ADDITIONAL',
      });
      params.martingalePriceStepCoefficient = preset.ordersPriceChangeScale;
    }

    if (additionalEntryOrdersState.isOpened && additionalEntryOrdersState.segment.value === 'alert') {
      const alert = additionalEntryOrdersState.segment.alert;

      params.alertAdditionalBaseOrderType = `CONDITIONAL_${alert.orderType.toUpperCase()}`;
      params.martingaleVolumeCoefficient = alert.ordersSizeScale;
      params.maxSafetyOrders = alert.orders;
    }

    const isCloseOrdersPreset = closeOrdesState.segment.value === 'preset';
    if (!isCloseOrdersPreset && autoState.segment !== 'auto') {
      params.alertCloseType = closeOrdesState.segment.alert.orderType.toUpperCase();
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.takeProfit.isOpened) {
      const takeProfit = closeOrdesState.segment.preset.takeProfit;

      params.takeProfitType = takeProfit.orderType === 'market' ? `CONDITIONAL_${takeProfit.orderType.toUpperCase()}` : takeProfit.orderType.toUpperCase();
      params.takeProfitPricePercent = convertDirection({
        value: takeProfit.priceChange,
        stratage: 'LONG',
        orderType: 'TAKE_PROFIT',
      });
      params.takeProfitPriceStepCoefficient = takeProfit.orderPriceChangeScale;
      params.takeProfitTargetsQuantity = takeProfit.orders;
      params.takeProfitVolumeStepCoefficient = takeProfit.orderSizeScale;
    }

    if (isCloseOrdersPreset && closeOrdesState.segment.preset.stopLoss.isOpened) {
      const stopLoss = closeOrdesState.segment.preset.stopLoss;

      params.stopLossType = `CONDITIONAL_${stopLoss.orderType.toUpperCase()}`;
      params.stopLossPercent = convertDirection({
        value: stopLoss.priceChange,
        stratage: 'LONG',
        orderType: 'STOP_LOSS',
      });
    }

    if (autoState.segment === 'auto') {
      params.settingsType = autoState.preset;

      params.martingaleVolumeCoefficient = autoState.values.additionalOrderSizeScale;
      params.maxSafetyOrders = autoState.values.maxAdditionalOrders;
      params.priceDeviationPercentSafety = autoState.values.additionalOrderPriceChange;
      params.martingalePriceStepCoefficient = autoState.values.additionalOrderPriceChangeScale;
      params.takeProfitPricePercent = convertDirection({
        value: autoState.values.taketProfitPercent,
        stratage: 'LONG',
        orderType: 'TAKE_PROFIT',
      });
      params.stopLossPercent = convertDirection({
        value: autoState.values.stopLossPercent,
        stratage: 'LONG',
        orderType: 'STOP_LOSS',
      });
      params.additionalBaseOrderType = 'CONDITIONAL_MARKET';
      params.alertAdditionalBaseOrderType = 'CONDITIONAL_MARKET';
      params.stopLossType = 'CONDITIONAL_MARKET';
      params.takeProfitType = 'CONDITIONAL_MARKET';
    }

    if (id) {
      params.botUuid = id;
    }
    
    const response = await (id ? updateBot(params) : createBot(params));
    if (!response.success) {
      toast.error(response.data.message);
      return;
    }

    if (!id) {
      ReactPixel.trackCustom('CreateAlertBot');
    }
    
    navigate(appPath.trading_bots_my_bot.navigate(response.data.fields.botUuid));
  };
  
  const handleModalDisplay = () => {
    setIsModalOpened((prev) => !prev);
  };

  const changeCurrentStep = (step?: number) => {
    methods.setValue('currentStep', typeof step === 'number' ? step : (methods.getValues('currentStep') + 1));
  };
  
  const changeDeffineStep = (step: number) => {
    if (!tradingAmount) {
      return;
    }

    methods.setValue('currentStep', step);
  };

  const handleBack = () => {
    methods.setValue('currentStep', (methods.getValues('currentStep') - 1));
  };

  const getContent = () => {
    switch (currentStep) {
    case 0:
      return (
        <ChooseAccount />
      );
    case 1:
      return (
        <ChooseSource
          handleBack={handleBack}
          secretToken={token}
          sourceUuid={sourceUuid}
        />
      );
    case 4:
      return (
        <AlertMessage
          createBot={handleModalDisplay}
          handleBack={handleBack}
        />
      );
    default:
      return (
        <ConfigureRiskManagment
          changeCurrentStep={changeCurrentStep}
          handleBack={handleBack}
          currentStep={currentStep}
          createBot={handleModalDisplay}
        />
      );
    }
  };

  const getSteps = useCallback(() => {
    const steps = defaultSteps(t, currentStep);

    if (autoState.segment === 'auto') {
      return steps;
    }

    if (autoState.segment === 'custom' && chooseSourceAlertSource === 'strategy') {
      return steps.concat(strategyCustomSteps(t, currentStep));
    }

    return steps.concat({
      title: (
        <Text
          styles={STEP}
          type={currentStep === 3 ? 'success' : undefined}
        >
          {t('configurator.steps.4')}
        </Text>
      ),
    });
  }, [
    autoState.segment,
    currentStep,
    chooseSourceAlertSource,
    t,
  ]);

  return (
    <FormProvider {...methods}>
      <Stack
        sx={WRAPPER}
        component='form'
        onSubmit={(e) => e.preventDefault()}
      >
        <Steps
          progressDot={true}
          current={currentStep}
          size='small'
          items={getSteps()}
          responsive={true}
          onChange={changeDeffineStep}
          status={errors?.length ? 'error' : undefined}
        />

        {getContent()}

        <BotName
          isModalOpened={isModalOpened}
          handleNameModalState={setIsModalOpened}
          createBotHandler={methods.handleSubmit(createBotHandler)}
        />
      </Stack>

      <Preview
        onClose={() => {
          navigate(appPath.trading_bots_configurator.navigate());
        }}
      />
    </FormProvider>
  );
};
