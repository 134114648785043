import { currencyFormatter } from 'shared/helpers/currency-formatter';

export const getUniqueSymbolCodes = (allExchangeSymbols: any[]) => {

  const result = allExchangeSymbols.reduce((uniqueSymbols, symbol) => {
    if (!uniqueSymbols.some((s: any) => s.quoteAsset === symbol.quoteAsset)) {
      uniqueSymbols.push(symbol);
    }
    return uniqueSymbols;
  }, []);
  
  return result;
};
  
export const calculateMarketOptions = (userWallet: any[], uniqueSymbolCodes: any[]) => {
  const options = uniqueSymbolCodes.map(token => {
    const walletBalance = userWallet?.find(el => el.symbol === token.quoteAsset)?.free || '0';
    const formattedBalance = currencyFormatter(walletBalance, token.chartPrecision);
  
    return {
      label: token.quoteAsset,
      value: `${formattedBalance}-${token.quoteAsset}`,
      balance: parseFloat(walletBalance),
      formattedValue: formattedBalance,
    };
  });
  
  const sorted = options.sort((a, b) => b.balance - a.balance);
  
  return sorted;
};
  
export const formatTradingPair = (baseAsset: string, quoteAsset: string) => {
  return `${baseAsset}${quoteAsset}`;
};

export const getFilteredTradingPairs = (exchangeSymbols: any[], defaultMarket: any, userWallet: any[]) => {

  const filteredExchangeSymbols = exchangeSymbols.filter((el) =>
    el.quoteAsset === defaultMarket?.label && !el.symbol.includes('-'),
  );
  
  //добавить округления здесь
  const mappedSymbols = filteredExchangeSymbols.map((el, index) => {
    const balance = userWallet?.find((elem) => elem.symbol === el.baseAsset)?.free ?? '0';
      
    const formattedValue = currencyFormatter(balance, el.baseAssetPrecision);
      
    return {
      title: `${el.baseAsset} - ${el.quoteAsset}`,
      value: `${formattedValue}-${index}`,
      label: `${el.baseAsset} - ${el.quoteAsset}`,
      balance: balance,
      formattedValue: formattedValue,
      formattedPair: formatTradingPair(el.baseAsset, el.quoteAsset),
    };
  });
  
  const sortedSymbols = mappedSymbols.sort((a, b) => b.balance - a.balance);
  
  return sortedSymbols;
};

export const setDefaultTradingPairAndSymbol = (
  tradingPairs: any[],
  allExchangeSymbols: any[],
) => {
  const defaultTradingPair = 
            tradingPairs.find(pair => pair.formattedPair === 'BTCUSDT') || 
            tradingPairs[0];
        
  const currentSymbol = defaultTradingPair 
    ? allExchangeSymbols.find(el => el.symbol === defaultTradingPair.formattedPair)
    : null;
        
  return {
    defaultTradingPair, currentSymbol, 
  };
};
