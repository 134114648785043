import { Box } from '@mui/material';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlatformCard } from 'widgets';
import { EMPTY_ARRAY } from 'shared/consts/common-constants';
import { formatter } from 'shared/helpers';
import { useQuery } from 'shared/hooks';
import { PlusGreen } from 'shared/icons';
import { Icon, Loader, MainButton, Title } from 'shared/ui';
import {
  ConnectNewExchange,
  EmptySubscription,
} from 'shared/ui/modals';
import { UpdateExchange } from 'shared/ui/modals/update-exchange';
import { useMyAccountsList } from '../hooks';
import { IAccountListPageProps } from '../interfaces';
import { connectButton, list, title, titleWrapper, wrapper } from '../styles';

export const ConnectedExchangeAccountListPage = (props: IAccountListPageProps) => {
  const [updateApiKeysModalOpen, setUpdateApiKeysModalOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  
  const [isEmptySubscriptionsOpen, setIsEmptySubscriptionsOpen] = useState<boolean>(false);

  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalDescription, setModalDescription] = useState<string>('');
  const [modalButtonTitle, setModalButtonTitle] = useState<string>('');
  const [modalButtonAction, setModalButtonAction] = useState<any>(null);
  const [modalType, setModalType] = useState<'error' | 'success'>('success');
  
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const {
    t, 
  } = useTranslation();

  const {
    values: {
      isLoading,
      modifiedExchanges,
      selectedExchange,
    },
    handlers: {
      deleteExchangeHandler,
      setSelectedExchange,
      changeModeHandler,
      handleUpdateExchange,
    },
  } = useMyAccountsList({
    exchanges: props.exchanges,
    refetchAccounts: props.refetchAccounts,
  });

  const {
    state,
  } = location;

  const exchangeConnectionStatus = state?.success;
  const exchangeConnectionMessage = state?.message;

  const isCryptoComConnected = query.get('mt') === 'crypto-connected';
  const isCryptoComUpdated = query.get('mt') === 'crypto-updated';

  const isDemoMode = useSelector(getIsDemoMode);

  useEffect(() => {
    if (exchangeConnectionStatus === true) {
      handleOpenEmptySubscriptionsModal(
        t('success.exchange.list.connected.title'),
        t('success.exchange.list.connected.message'),
        t('success.exchange.list.connected.button'),
        () => {
          setIsEmptySubscriptionsOpen(false);
          navigate(location.pathname, {
            replace: true, 
          });
        },
      );
    } else if (exchangeConnectionStatus === false) {
      handleOpenEmptySubscriptionsModal(
        t('error.exchange.list.failed.title'),
        (`error.exchange.list.failed.message.${exchangeConnectionMessage}`),
        t('error.exchange.list.failed.button'),
        () => {
          setIsEmptySubscriptionsOpen(false);
          navigate(location.pathname, {
            replace: true, 
          });
        },
        true,
      );
    }
  }, [exchangeConnectionStatus]);

  const modalHandler = (action: Dispatch<SetStateAction<boolean>>) => {
    return () => {
      action((prev) => !prev);
    };
  };

  const handleOpenEmptySubscriptionsModal = (title: string, description: string, buttonTitle: string, buttonAction?: () => void, isError?: boolean) => {
    setModalTitle(title);
    setModalDescription(description);
    setModalButtonTitle(buttonTitle);
    setIsEmptySubscriptionsOpen(true);
    setModalButtonAction(() => {
      return buttonAction;
    });
    setModalType(isError ? 'error' : 'success');
  };

  const handleOpenDeleteModal = (exchangeAccountUuid: string) => {
    handleOpenEmptySubscriptionsModal(
      t('modal.exchange.list.delete.title'),
      t('modal.exchange.list.delete.message'),
      t('modal.exchange.list.delete.button'),
      async () => {
        await deleteExchangeHandler(exchangeAccountUuid);
        setIsEmptySubscriptionsOpen(false);
      },
    );
  };

  const handleOpenUpdateApiKeysModal = (exchange: ExchangeAccount) => {
    return () => {
      setSelectedExchange(exchange);
      setUpdateApiKeysModalOpen(true);
    };
  };

  useEffect(() => {
    if (isCryptoComConnected) {
      ReactPixel.trackCustom('ConnectExchange');
    }

    if (!(isCryptoComConnected || isCryptoComUpdated)) {
      return;
    }
    
    toast.success(t('success.exchange.list.completed.title') as string);
  }, [isCryptoComConnected, isCryptoComUpdated]);

  if (isLoading) {
    return (
      <Loader />
    );
  }
  
  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={title}>
          {t('exchange.connected.title')}
        </Title>

        {!isDemoMode && (
          <MainButton 
            type='primary' 
            ghost={true}
            styles={connectButton} 
            onClick={modalHandler(setIsModalOpen)}
            icon={(
              <Icon width={16}>
                {PlusGreen} 
              </Icon>
            )}
          >
            {t('exchange.connected.button')}
          </MainButton>
        )}
      </Box>

      <Box sx={list}>
        {modifiedExchanges.map((exchange: ExchangeAccount) => (
          <PlatformCard
            key={exchange.exchangeAccountUuid}
            title={exchange.accountName}
            exchangeCode={exchange.exchangeCode}
            marginMode={exchange.marginMode}
            isDemoAccount={exchange.exchangeCode === 'demo'}
            dailyChange={{
              usdt: {
                value: formatter(exchange.usdtDailyChangeValue),
                percent: exchange.usdtDailyChangePercent,
              },
              btc: {
                value: formatter(exchange.btcDailyChangeValue),
                percent: exchange.btcDailyChangePercent,
              },
            }}
            exchangeAccountUuid={exchange.exchangeAccountUuid}
            total={{
              usd: formatter(exchange.totalUsdt) ?? 'no value',
              btc: formatter(exchange.totalBtc) ?? 'no value',
            }}
            balances={exchange.balances ?? EMPTY_ARRAY}
            onDelete={handleOpenDeleteModal}
            onUpdateApiKeys={handleOpenUpdateApiKeysModal(exchange)}
            changeMarginMode={(marginMode: boolean) => {
              changeModeHandler({
                exchangeAccountUuid: exchange.exchangeAccountUuid,
                marginMode: marginMode ? 'CROSS' : 'ISOLATED',
              });
            }}
            handleUpdateExchange={() => {
              handleUpdateExchange(exchange.exchangeAccountUuid);
            }}
          />
        ))}
      </Box>

      <ConnectNewExchange 
        isModalOpen={isModalOpen} 
        setIsModalOpen={modalHandler(setIsModalOpen)} 
      />

      <EmptySubscription 
        isOpen={isEmptySubscriptionsOpen} 
        modalTitle={modalTitle}
        modalDescription={modalDescription}
        handleClose={modalHandler(setIsEmptySubscriptionsOpen)}
        button={{
          title: modalButtonTitle,
          action: modalButtonAction,
        }}
        isError={modalType === 'error'}
      />

      <UpdateExchange
        isUpdateModalOpen={updateApiKeysModalOpen}
        isModalView={false}
        platform={selectedExchange?.exchangeCode || ''}
        closeExchangeModal={modalHandler(setUpdateApiKeysModalOpen)}
        exchangeAccountUuid={selectedExchange?.exchangeAccountUuid || ''}
        currentExchangeTitle={selectedExchange?.accountName || ''}
      />
    </Box>
  );
};
