import { Box, Stack } from '@mui/material';
import { Collapse } from 'antd';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { useTradeSymbols } from 'pages/manual-trading/trading-terminal/components/active-trades/hooks/use-trade-symbols';
import {
  formatConditionalPrice,
  formatPrice,
  formatTotal,
  formatUnits,
  getConditionalPrice,
  getPrice,
  getTextColorBySide,
  getUnits,
  groupOrders,
  orderPriority,
  orderTypeMapping,
  sortOrders,
  viewTypeMapping,
} from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { capitalizeFirstLetter, formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Order, SmartTrade, ViewType } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatter } from 'shared/helpers';
import { ExpandPrimary, ExpandSecondary } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { getPositionInfo } from '../../api/get-position-info';
import { ActiveTrade } from '../../components';

interface IRenderOrderGroup {
  orders: Order[];
  viewType: ViewType;
  trade: SmartTrade;
  tradeSymbol: CurrentSymbol;
  t: any;
}
const renderOrderGroup = (params: IRenderOrderGroup) => {
  const {
    orders,
    viewType,
    trade,
    tradeSymbol,
    t,
  } = params;
  
  if (!orders || orders.length === 0) return null;

  return (
    <Box
      key={viewType}
      mb={3}
      minWidth='100dvh'
    >
      <Box
        display='flex'
        alignItems='center'
        gap={2}
        mb={2}
      >
        <Title level={4}>
          {viewTypeMapping[viewType]}
        </Title>

        <Text type={getTextColorBySide(orders[0].side)}>
          {orders[0].side}
        </Text>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
          gap: 1,
          '&>span': {
            display: 'flex',
            flex: 1,
            gap: 1,
            whiteSpace: 'nowrap',
            wordBreak: 'keep-all',
            minWidth: '100px',
          },
        }}
      >
        <Text type='secondary'>
          {t('terminal.trades.columns.price.origin')}
        </Text>

        <Text type='secondary'>
          {t('terminal.trades.columns.units')}
        </Text>

        <Text type='secondary'>
          {t('terminal.trades.columns.price.conditional')}
        </Text>

        <Text type='secondary'>
          {t('terminal.trades.columns.total')}
        </Text>

        <Text type='secondary'>
          {t('terminal.trades.columns.type')}
        </Text>
        
        <Text type='secondary'>
          {t('terminal.trades.columns.status.title')}
        </Text>
      </Box>

      {orders.map((order, index) => {
        const price = getPrice(order);
        const formattedPrice = formatByPrecisionAndTrim(price ?? '', tradeSymbol.quoteAssetPrecision, Number(tradeSymbol.priceMin), Number(tradeSymbol.priceMax));
        
        const units = getUnits(order);
        const formattedUnits = formatByPrecisionAndTrim(units ?? '', tradeSymbol.baseAssetPrecision, Number(tradeSymbol.priceMin), Number(tradeSymbol.priceMax));
        
        
        const conditionalPrice = getConditionalPrice(order);
        const formattedConditionalPrice = formatByPrecisionAndTrim(conditionalPrice ?? '', tradeSymbol.quoteAssetPrecision, Number(tradeSymbol.priceMin), Number(tradeSymbol.priceMax));
        
        const total = formatTotal(price ? parseFloat(price) : null, units ? parseFloat(units) : null);
        const formattedTotal = formatByPrecisionAndTrim(total ?? '', tradeSymbol.quoteAssetPrecision, Number(tradeSymbol.priceMin), Number(tradeSymbol.priceMax));
        
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 1,
              gap: 1,
              '&>span': {
                display: 'flex',
                flex: 1,
                gap: 1,
                whiteSpace: 'nowrap',
                wordBreak: 'keep-all',
                minWidth: '100px',
              },
            }}
          >
            <Text>
              {formatPrice(formattedPrice ? parseFloat(formattedPrice) : null, trade.quoteSymbol)}
            </Text>

            <Text>
              {formatUnits(formattedUnits ? parseFloat(formattedUnits) : null, trade.baseSymbol)}
            </Text>

            <Text>
              {formatConditionalPrice(formattedConditionalPrice ? parseFloat(formattedConditionalPrice) : null, trade.quoteSymbol)}
            </Text>

            <Text>
              {formattedTotal ? `${formattedTotal} ${trade.quoteSymbol}` : ''}
            </Text>

            <Text>
              {orderTypeMapping[order.orderType]}
            </Text>
            
            <Text>
              {capitalizeFirstLetter(t(`terminal.trades.columns.status.items.${order.status}`))}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

// 1) Создаём тип для данных, приходящих по futures
interface IFuturesData {
  smartTradeUuid: string;
  markPrice: string;
  liquidationPrice: string;
  leverage: number;
  marginMode: string; // например: 'ISOLATED'
  status: string;     // например: 'Normal'
}

interface IRenderFuturesGroup {
  data: IFuturesData;
  t: any;
}

/**
 * Рендерим отдельный блок для данных по фьючерсам.
 * В нём, например, показываем Margin Type (marginMode) и Leverage.
 */
const renderFuturesGroup = (params: IRenderFuturesGroup) => {
  const {
    data, t, 
  } = params;
  
  const {
    marginMode,
    leverage,
    status,
    markPrice,
    liquidationPrice,
  } = data;
  
  if (!data) return null;

  return (
    <Box
      // ключ нужен, если этот компонент будет в списке .map(...)
      key='futures'
      mb={3}
      minWidth='100dvh'
    >
      <Box
        display='flex'
        alignItems='center'
        gap={2}
        mb={2}
      >
        <Title level={4}>
          {/* Название блока (можно задать любое) */}
          {t('terminal.panel.leverageSettings.positionInfo')} 
        </Title>
      </Box>

      {/* Шапка таблицы: */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
          gap: 1,
          '&>span': {
            display: 'flex',
            flex: 1,
            gap: 1,
            whiteSpace: 'nowrap',
            wordBreak: 'keep-all',
            minWidth: '100px',
          },
        }}
      >
        <Text type='secondary'>
          {/* Например, Margin Type или Margin Mode */}
          {t('terminal.panel.leverageSettings.marginType')}
        </Text>

        <Text type='secondary'>
          {t('terminal.panel.leverageSettings.leverage')}
        </Text>

        <Text type='secondary'>
          {t('terminal.panel.leverageSettings.markPrice')}
        </Text>

        <Text type='secondary'>
          {t('terminal.panel.leverageSettings.liquidationPrice')}
        </Text>

        <Text type='secondary'>
          {t('terminal.panel.leverageSettings.status')}
        </Text>
      </Box>

      {/* Данные по futures: */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 1,
          gap: 1,
          '&>span': {
            display: 'flex',
            flex: 1,
            gap: 1,
            whiteSpace: 'nowrap',
            wordBreak: 'keep-all',
            minWidth: '100px',
          },
        }}
      >
        <Text>
          {capitalizeFirstLetter(marginMode)}
        </Text>

        <Text>
          {`x${leverage}`}
        </Text>

        <Text>
          {formatter(markPrice)}
        </Text>

        <Text>
          {formatter(liquidationPrice)}
        </Text>

        <Text>
          {status}
        </Text>
      </Box>
    </Box>
  );
};

interface ITradeItemProps {
  trade: SmartTrade;
  exchanges: any[];
}
export const TradeItem = (props: ITradeItemProps) => {
  const {
    trade, 
    exchanges,
  } = props;
  
  const [futuresData, setFuturesData] = useState<IFuturesData | null>(null);
  const [futuresDataLoaded, setFuturesDataLoaded] = useState(false);
  const tradeSymbol = useTradeSymbols(trade);
  const sortedOrders = sortOrders(trade.orders);
  const groupedOrders = groupOrders(sortedOrders);

  const {
    t, 
  } = useTranslation();

  const isFutures = trade.exchangeCode?.includes('-futures');
  
  const handleCollapseChange = (key: string | string[]) => {
    const activeKeys = Array.isArray(key) ? key : [key];

    // Если это фьючерсная сделка, панель открыта и данные ещё не загружены:
    if (isFutures && activeKeys.includes('1') && !futuresDataLoaded) {
      (async () => {
        try {
          const data = await getPositionInfo(trade.smartTradeUuid);
          const parsedData = data?.data;
          setFuturesData(parsedData);
          setFuturesDataLoaded(true);
        } catch (error) {
          console.error('Error fetching futures data', error);
        }
      })();
    }
  };
  
  const exchangeAccount = useMemo(() => {
    if (!(trade?.baseSymbol || !exchanges?.length)) {
      return '';
    }

    const account = exchanges.find((exchange) => exchange.value === trade.exchangeAccountUuid);
    return account;
  }, [trade, exchanges]);
  
  if (!tradeSymbol) return null;
  
  return (
    <Collapse
      expandIcon={(props) => props.isActive ? <ExpandPrimary /> : <ExpandSecondary />}
      style={{
        background: 'transparent',
        padding: '35px 16px',
        border: '1px solid #E9E9E9',
      }}
      collapsible='icon'
      bordered={false}
      onChange={handleCollapseChange}
      items={[
        {
          key: 1,
          label: (
            <ActiveTrade
              trade={trade}
              tradeSymbol={tradeSymbol}
              chartLastPrice={222222}
              exchangeAccount={exchangeAccount}
            />
          ),
          children: (
            <Box
              sx={{
                overflowX: 'auto',
              }}
            >
              <Stack gap={3}>
                
                {futuresData && renderFuturesGroup({
                  data: futuresData, t, 
                })}
                
                {orderPriority.map(viewType => renderOrderGroup({
                  orders: groupedOrders[viewType],
                  viewType,
                  trade,
                  tradeSymbol,
                  t,
                }))}
              </Stack>
            </Box>
          ),
        },
      ]}
    />
  );
};
