import { getUserAuthenticated } from 'entities/user/model/selectors/get-user-authenticated/get-user-authenticated';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useSelector } from 'react-redux';
import { ENV } from 'shared/config/environment';
import { getRouter } from 'shared/helpers';
import { useLanguage } from 'shared/hooks';
import AppRouter from 'app/providers/router/ui/app-router';

function App() {
  useLanguage();

  const isAuthorized = useSelector(getUserAuthenticated);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    app?.ready();

    ReactPixel.init(ENV.PIXEL_ID!, undefined, {
      autoConfig: true,
      debug: false,
    });
  }, []);

  return getRouter({
    router: (
      <AppRouter />
    ),
    isAuthorized: !!isAuthorized,
  });
}

export default App;
