interface EnvironmentConfig {
  API_URL: string;
  APP_CLIENT_URL: string;
  PIXEL_ID: string;
  CRYPTOCOMPARE_API_KEY: string;
  ENV: 'development' | 'stage' | 'prod';
}
  
export const ENV: EnvironmentConfig = {
  API_URL: process.env.REACT_APP_API_URL || 'https://api.skyrexio.com',
  APP_CLIENT_URL: process.env.REACT_APP_CLIENT_URL || 'https://app.skyrexio.com',
  CRYPTOCOMPARE_API_KEY: process.env.REACT_APP_CRYPTOCOMPARE_API_KEY || '',
  PIXEL_ID: process.env.REACT_APP_PIXEL_ID || '',
  ENV: (process.env.REACT_APP_ENV as EnvironmentConfig['ENV']) || 'prod',
};
