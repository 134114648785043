import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { calculatePercentDifference, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'app/providers/store-provider/config/store';

export function getDefaultTakeProfitOrderPrice(selectedSide: string, currentOrderPrice: number): number {
  const normalizedSide = selectedSide.toUpperCase();

  // Если side = BUY или LONG, то используем 0.95 от текущей цены
  if (normalizedSide === 'BUY' || normalizedSide === 'LONG') {
    return currentOrderPrice * 1.1;
  }
  
  // Если side = SELL или SHORT, то устанавливаем фиксированное значение 1.05
  if (normalizedSide === 'SELL' || normalizedSide === 'SHORT') {
    return currentOrderPrice * 0.9;
  }

  // На всякий случай, если side отличается от ожидаемых, возвращаем стандартное значение
  return currentOrderPrice * 1.1;
}

export const useTakeProfitInputsLogic = (
  currentSymbol: any, 
) => {
  const {
    watch, setValue, getValues, setError, 
  } = useFormContext();
  const dispatch = useAppDispatch();
  
  const orderPrice = watch('orderPrice');
  const takeProfitOrderType = watch('takeProfitOrderType');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const isTakeProfitEnabled = watch('isTakeProfitEnabled');
  const selectedSide = watch('selectedSide');
  const handleTakeProfitTriggerPriceChange = useCallback((value: string) => {
    const triggerPrice = parseFloat(value);
    const basePrice = parseFloat(orderPrice);

    if (!isNaN(triggerPrice)) {
      setValue('takeProfitTriggerPrice', triggerPrice.toString());

      if (!isNaN(basePrice) && basePrice !== 0) {
        const percentDifference = calculatePercentDifference(triggerPrice, basePrice);
        setValue('takeProfitTriggerPricePercent', percentDifference);
      } else {
        setValue('takeProfitTriggerPricePercent', '0');
      }
      // Здесь можно добавить дополнительную логику, если необходимо
    }
  }, [setValue, orderPrice, calculatePercentDifference]);  
    
  const handleTakeProfitOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
        
    if (!isNaN(price) && !isNaN(currentOrderPrice)) {
      const percentDifference = calculatePercentDifference(price, currentOrderPrice);
      setValue('takeProfitOrderPricePercent', percentDifference.toString());
    }
        
  }, [setValue, orderPrice, calculatePercentDifference]);
      
  
  const handleTakeProfitOrderPricePercentChange = (value: string) => {
    const newPercent = parseFloat(value);
    const currentOrderPrice = parseFloat(orderPrice);
    
    if (!isNaN(newPercent) && !isNaN(currentOrderPrice)) {
      const newTakeProfitOrderPrice = currentOrderPrice * (1 + newPercent / 100);
      const formattedTakeProfitOrderPrice = formatByPrecisionAndTrim(newTakeProfitOrderPrice.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
      setValue('takeProfitOrderPrice', formattedTakeProfitOrderPrice);
    }
  };
  
  const handleAddTakeProfitEntry = useCallback(() => {
    const {
      takeProfitOrderPrice, 
      takeProfitOrderPricePercent, 
      takeProfitTriggerPrice, 
      takeProfitEntries, 
      takeProfitSlider, 
      takeProfitTriggerPriceType,
      units,
    } = getValues();
    const price = takeProfitOrderType === 'cond.market' ? takeProfitTriggerPrice : takeProfitOrderPrice;
    const volume = roundPercentage(takeProfitSlider);
    
    if (volume <= 0) {
      setError('takeProfitInvalidVolumeError', {
        type: 'manual',
        message: 'Should be more than 0%',
      });
      return;
    }
    
    const calculatedValue = parseFloat(price) * (volume / 100) * parseFloat(units);
    
    if (calculatedValue < parseFloat(currentSymbol.minNotional)) {
      toast.error('Order volume is less than minimal');
      return;
    }
    
    const newEntry = {
      takeProfitOrderType,
      price: parseFloat(price),
      orderPricePercent: takeProfitOrderPricePercent,
      volume: volume,
      typeTriggerPrice: takeProfitTriggerPriceType,
    };
    
    if (takeProfitEntries.some((entry: { price: number; }) => entry.price === newEntry.price)) {
      toast.error('Price assigned to another order.');
      return;
    }
    
    const updatedEntries = [...takeProfitEntries, newEntry];
    setValue('takeProfitEntries', updatedEntries as any, {
      shouldValidate: true, 
    });
    
    const totalVolume = updatedEntries.reduce((sum, entry) => sum + entry.volume, 0);
  
    const newSliderValue = Math.max(0, 100 - totalVolume);
    setValue('takeProfitSlider', newSliderValue);
    
    dispatch(newTerminalActions.addTakeProfitLine({
      price: parseFloat(price),
      type: takeProfitOrderType,
      volume: volume,
      index: updatedEntries.length - 1,
    }));
    
  }, [getValues, setValue, takeProfitTriggerPrice, takeProfitOrderPrice, takeProfitOrderType, currentSymbol.minNotional]);
  
  const handleRemoveTakeProfitEntry = useCallback((index: number) => {
    const {
      takeProfitEntries, 
    } = getValues();
    const updatedEntries = takeProfitEntries.filter((_: any, i: number) => i !== index);
    setValue('takeProfitEntries', updatedEntries, {
      shouldValidate: true, 
    });
    
    // Удаляем линию из Redux
    dispatch(newTerminalActions.removeTakeProfitLine(index));
    
    updatedEntries.forEach((entry: any, newIndex: number) => {
      if (newIndex >= index) {
        // Если это линия, которая была после удаленной, обновляем её индекс
        dispatch(newTerminalActions.updateTakeProfitLine({
          oldIndex: newIndex + 1, // старый индекс
          index: newIndex, // новый индекс
          price: entry.price,
        }));
      }
    });
        
  }, [getValues, setValue]);
  
    
  const clearAllTakeProfitEntries = useCallback(() => {
    setValue('takeProfitEntries', []);
    dispatch(newTerminalActions.clearTakeProfitLines());
  }, [setValue, dispatch]);
  
  const updateTakeProfitValues = useCallback(() => {
    if (isTakeProfitEnabled) {
      return; 
    }
  
    const currentOrderPrice = parseFloat(orderPrice);
    const currentTakeProfitOrderPrice = getDefaultTakeProfitOrderPrice(selectedSide, currentOrderPrice);
    const percentDifference = calculatePercentDifference(currentTakeProfitOrderPrice, currentOrderPrice);
  
    const roundedTakeProfitOrderPrice = formatByPrecisionAndTrim(
      currentTakeProfitOrderPrice.toString(),
      currentSymbol.chartPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
  
    const roundedPercentDifference = Math.round(percentDifference).toString();
  
    setValue('takeProfitOrderPrice', roundedTakeProfitOrderPrice);
    setValue('takeProfitTriggerPrice', roundedTakeProfitOrderPrice);
    setValue('takeProfitOrderPricePercent', roundedPercentDifference);
    setValue('takeProfitTriggerPricePercent', roundedPercentDifference);
  }, [
    selectedSide,
    isTakeProfitEnabled, 
    orderPrice, 
    setValue, 
    currentSymbol?.priceMin,
    currentSymbol?.priceMax,
    currentSymbol?.chartPrecision,
  ]);

  return {
    handleTakeProfitTriggerPriceChange,
    handleTakeProfitOrderPriceChange,
    handleTakeProfitOrderPricePercentChange,
    handleAddTakeProfitEntry,
    handleRemoveTakeProfitEntry,
    updateTakeProfitValues,
    clearAllTakeProfitEntries,
  };
};
