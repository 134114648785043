import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getPositionInfo = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/smartTrade/getPositionInfo',
    data: {
      smartTradeUuid,
    },
  });
  return response.data;
};
