import { initializeTradingData } from 'entities/new-terminal/model/services/initialize-trading-data';
import { updateExchangeData } from 'entities/new-terminal/model/services/update-trading-data';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState, useAppDispatch } from 'app/providers/store-provider/config/store';
import { getSharedTrade } from '../api/get-shared-trade';

export const useTrading = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isDemoMode = useSelector(getIsDemoMode);
  
  const { 
    exchanges,
    selectedExchange,
    financeData,
    snapshots,
    symbolCodes,
    marketOptions,
    selectedMarket,
    tradingPairs,
    selectedTradingPair,
    currentSymbol,
    chartSymbol,
    allExchangeSymbols,
    isLoading,
    error, 
    userWalletBalances,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    dailyChange,
    chosenExchange,
  } = useSelector((state: RootState) => state.newTerminal);

  useEffect(() => {
    const initializeTerminal = async () => {
      const shareId = searchParams.get('share');
      try {
        if (shareId) {
          // 1) Получаем данные shared trade
          const tradeDataResponse = await getSharedTrade(shareId);
          const tradeData = tradeDataResponse.data;

          // 3) Инициализируем store с учётом shared trade
          await dispatch(
            initializeTradingData({
              isDemoMode,
              sharedTradeData: {
                tradeDataRaw: tradeData.form,
                exchangeCode: tradeData.form.exchangeCode,
                baseAsset: tradeData.form.baseSymbol,
                quoteAsset: tradeData.form.quoteSymbol,
              },
            }),
          );
        } else {
          // Стандартная инициализация
          await dispatch(initializeTradingData({
            isDemoMode,
          }));
        }
      } catch (error) {
        console.error('Failed to initialize trading data:', error);
        toast.error('Не удалось загрузить данные');
        
        // В случае ошибки пытаемся выполнить стандартную инициализацию
        dispatch(initializeTradingData({
          isDemoMode,
        }));
      }
    };

    initializeTerminal();
  }, [dispatch, isDemoMode, searchParams]);
  
  const handleExchangeSelect = async (exchangeUuid: string, tradeSymbol?: { quote: string; base: string; }) => {
    dispatch(newTerminalActions.setSharedTrade(null));
    dispatch(newTerminalActions.setEditingTrade(null));
    
    const selectedExchange = exchanges.find(ex => ex.value === exchangeUuid);

    if (selectedExchange) {
      dispatch(newTerminalActions.setSelectedExchange(selectedExchange));
 
      let finalTradeSymbol = tradeSymbol;
      if (!tradeSymbol?.quote && selectedTradingPair?.label) {
        // Разбиваем строку вида "BTC - USDT" на массив и убираем пробелы
        const [base, quote] = selectedTradingPair.label.split('-').map((s: string) => s.trim());
        
        finalTradeSymbol = {
          base,
          quote,
        };
      }
      
      
      dispatch(updateExchangeData({
        uuid: selectedExchange.value,
        exchangeCode: selectedExchange.exchangeCode,
        previousMarket: selectedMarket,
        selectedExchange,
        tradeSymbol: finalTradeSymbol,
        selectedTradingPair,
      }));
    }
  };
  
  const handleMarketSelect = async (market: any) => {
    dispatch(newTerminalActions.setEditingTrade(null));
    dispatch(newTerminalActions.setSharedTrade(null));
    //TODO: переписать маппинг торговых пар, чтобы идентифицировать по value
    function getCurrency(symbol: string) {
      return symbol.split('-')[1];
    }
    
    const currency = getCurrency(market);
    
    const selectedMarket = marketOptions.find((option: any) => option.label === currency);
    
    dispatch(newTerminalActions.updateMarket({
      market: selectedMarket,
      allExchangeSymbols,
      userWallet: userWalletBalances,
    }));
  };
  
  
  const handleTradingPairSelect = (tradingPairValue: string) => {
    dispatch(newTerminalActions.setEditingTrade(null));
    dispatch(newTerminalActions.setSharedTrade(null));
    
    //TODO: переписать маппинг торговых пар, чтобы идентифицировать по value
    const selectedPair = tradingPairs.find(
      pair => pair.value === tradingPairValue,
    );
    
    if (selectedPair) {
      const symbol = allExchangeSymbols.find(
        el => el.symbol === selectedPair.formattedPair,
      );

      dispatch(newTerminalActions.setTradingPair({
        tradingPair: selectedPair,
        currentSymbol: symbol || null,
        userWalletBalance: userWalletBalances,
      }));
    }
  };

  return {
    exchanges,
    selectedExchange,
    financeData,
    snapshots,
    symbolCodes,
    marketOptions,
    selectedMarket,
    tradingPairs,
    dailyChange,
    selectedTradingPair,
    currentSymbol,
    chartSymbol,
    isLoading,
    error,
    handleExchangeSelect,
    handleMarketSelect,
    userWalletBalances,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    chosenExchange,
    handleTradingPairSelect,
  };
};
