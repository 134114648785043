import { createAsyncThunk } from '@reduxjs/toolkit';

// Наши типы, как в вопросе
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { getPositionInfo } from 'pages/manual-trading/trading-terminal/components/active-trades/api/get-position-info';
import { mapTradeToFormValues } from 'pages/manual-trading/trading-terminal/components/active-trades/components/active-trade/helpers/map-editing-trade';
import { toast } from 'react-toastify';
import { newTerminalActions } from '../slice/new-terminal-slice';
import { updateExchangeData } from './update-trading-data';

interface StartEditingTradeArg {
  trade: SmartTrade;
  exchangeAccount: ExchangeAccount;
  tradeSymbol: CurrentSymbol; 
}

export const startEditingTrade = createAsyncThunk(
  'newTerminal/startEditingTrade',
  async (args: StartEditingTradeArg, thunkAPI) => {
    const {
      dispatch, 
    } = thunkAPI;
    const {
      trade,
      exchangeAccount,
      tradeSymbol,
    } = args;

    const isFuturesAccount = trade.exchangeCode?.includes('-futures');
    
    try {
      let freshTrade = trade;
      
      if (isFuturesAccount) {
        const response = await getPositionInfo(trade.smartTradeUuid);
        const data = response.data;
      
        const leverageSettings = {
          leverage: data.leverage,
          leverageType: data.marginMode.toLowerCase(),
        };
      
        freshTrade = {
          ...freshTrade,
          leverageSettings,
        };
      }

      // Мапим в форму
      const formValues = mapTradeToFormValues(freshTrade, exchangeAccount, tradeSymbol);

      // Дальше делаем setEditingTrade, скроллим и т.д.
      dispatch(newTerminalActions.setSharedTrade(null));
      dispatch(newTerminalActions.setEditingTrade(formValues));
      dispatch(newTerminalActions.scrollToPanel());
      dispatch(newTerminalActions.setSelectedExchange(exchangeAccount));

      // Обновляем exchangeData (если нужно)
      dispatch(updateExchangeData({
        uuid: trade.exchangeAccountUuid,
        exchangeCode: exchangeAccount.exchangeCode,
        selectedExchange: exchangeAccount,
        tradeSymbol: {
          quote: tradeSymbol.quoteAsset,
          base: tradeSymbol.baseAsset,
        },
      }));

      // Если нужно что-то ещё…
      return formValues; 
    } catch (err: any) {
      toast.error(`Failed to start editing trade: ${err.message}`);
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);
