import { TLanguage, TTranslation } from 'shared/types';

export const panel = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      panel: {
        switch: {
          buy: {
            spot: 'Buy',
            futures: 'Long',
          },
          sell: {
            spot: 'Sell',
            futures: 'Short',
          },
        },
        skip: 'Skip base order (use existing funds)',
        title: {
          buy: {
            origin: 'Buy {{currency}}',
            skip: 'Bought {{currency}}',
            tooltip: '{{currency}} amount currently available on Exchange account',
          },
          sell: {
            origin: 'Sell {{currency}}',
            skip: 'Sold {{currency}}',
            tooltip: '{{currency}} amount currently available on Exchange account',
          },
          long: {
            origin: 'Long {{currency}}',
            skip: 'Short {{currency}}',
            tooltip: '{{currency}} amount currently available on Exchange account',
          },
          short: {
            origin: 'Long {{currency}}',
            skip: 'Short {{currency}}',
            tooltip: '{{currency}} amount currently available on Exchange account',
          },
        },
        segments: {
          limit: 'Limit',
          market: 'Market',
          conditional: 'Conditional',
        },
        fields: {
          price: {
            origin: 'Order price',
            skip: {
              buy: 'Bought price',
              sell: 'Sold price',
            },
          },
          units: 'Units',
          total: 'Total',
          triggerPrice: {
            title: 'Trigger price',
            segments: {
              limit: 'Limit',
              market: 'Market',
            },
          },
          types: {
            last: 'Last',
            bid: 'Bid',
            ask: 'ask',
          },
        },
        additional: {
          title: 'Additional entry',
          segments: {
            limit: 'Limit',
            market: 'Cond.market',
            conditional: 'Cond.limit',
          },
          table: {
            title: 'Safety orders',
            columns: {
              price: 'Price',
              total: 'Total',
            },
          },
          button: 'Add entry',
        },
        takeProfit: {
          title: 'Take profit',
          slider: {
            title: 'Average',
            tooltip: 'Automatically adjusts TP to maintain profit percentage when entry price is averaged. Example: entry is $100, TP set at $110 (+10%), if price is averaged to $95, TP becomes $104.5 which is still +10%',
          },
          segments: {
            limit: 'Limit',
            market: 'Cond.market',
          },
          columns: {
            price: 'Price',
            volume: 'Volume',
          },
          button: {
            add: 'Add target',
            split: 'Split target',
          },
          all: 'All targets are set',
        },
        stopLoss: {
          title: 'Stop loss',
          slider: {
            title: 'Average',
            tooltip: 'Automatically adjusts TP to maintain profit percentage when entry price is averaged. Example: entry is $100, TP set at $110 (+10%), if price is averaged to $95, TP becomes $104.5 which is still +10%',
          },
          segments: {
            limit: 'Cond.Limit',
            market: 'Cond.market',
          },
          columns: {
            price: 'Price',
            volume: 'Volume',
          },
        },
        button: {
          create: 'Create trade',
          update: 'Update trade',
          cancel: 'Cancel',
        },
        leverageSettings: {
          positionInfo: 'Position Info',
          markPrice: 'Mark Price',
          liquidationPrice: 'Liquidation Price',
          status: 'Status',
          marginType: 'Margin Type',
          leverage: 'Leverage',
          isolated: 'Isolated',
          cross: 'Cross',
        },
      },
    },
    ru: {
      panel: {
        switch: {
          buy: {
            spot: 'Купить',
            futures: 'Лонг',
          },
          sell: {
            spot: 'Продать',
            futures: 'Шорт',
          },
        },
        skip: 'Пропустить вход (исп. имеющиеся средства)',
        title: {
          buy: {
            origin: 'Купить {{currency}}',
            skip: 'Куплено {{currency}}',
            tooltip: '{{currency}} доступно на биржевом аккаунте',
          },
          sell: {
            origin: 'Продать {{currency}}',
            skip: 'Продано {{currency}}',
            tooltip: '{{currency}} доступно на биржевом аккаунте',
          },
          long: {
            origin: 'Лонг {{currency}}',
            skip: 'Шорт {{currency}}',
            tooltip: '{{currency}} доступно на биржевом аккаунте',
          },
          short: {
            origin: 'Лонг {{currency}}',
            skip: 'Шорт {{currency}}',
            tooltip: '{{currency}} доступно на биржевом аккаунте',
          },
        },
        segments: {
          limit: 'Лимит',
          market: 'Маркет',
          conditional: 'Условный',
        },
        fields: {
          price: {
            origin: 'Цена ордера',
            skip: {
              buy: 'Цена покупки',
              sell: 'Цена продажи',
            },
          },
          units: 'Единицы',
          total: 'Итого',
          triggerPrice: {
            title: 'Триггер цена',
            segments: {
              limit: 'Лимит',
              market: 'Маркет',
            },
          },
          types: {
            last: 'Ласт',
            bid: 'Бид',
            ask: 'Аск',
          },
        },
        additional: {
          title: 'Дополнительный вход',
          segments: {
            limit: 'Лимит',
            market: 'Усл. маркет',
            conditional: 'Усл. лимит',
          },
          table: {
            title: 'Доп. входы',
            columns: {
              price: 'Цена',
              total: 'Всего',
            },
          },
          button: 'Добавить вход',
        },
        takeProfit: {
          title: 'Тейк профит',
          slider: {
            title: 'Средний',
            tooltip:
              'Автоматическая корректировка TP при усреднении цены входа (пример: вход $100, TP +10%, усреднение до $95 => TP скорректируется к +10%)',
          },
          segments: {
            limit: 'Лимит',
            market: 'Усл. маркет',
          },
          columns: {
            price: 'Цена',
            volume: 'Объем',
          },
          button: {
            add: 'Добавить цель',
            split: 'Разделить цели',
          },
          all: 'Все цели заданы',
        },
        stopLoss: {
          title: 'Стоп лосс',
          slider: {
            title: 'Средний',
            tooltip:
              'Автоматическая корректировка SL при усреднении входа (пример аналогичен TP)',
          },
          segments: {
            limit: 'Усл. лимит',
            market: 'Усл. маркет',
          },
          columns: {
            price: 'Цена',
            volume: 'Объем',
          },
        },
        button: {
          create: 'Создать сделку',
          update: 'Обновить сделку',
          cancel: 'Отмена',
        },
        leverageSettings: {
          leverage: 'Плечо',
          isolated: 'Изолированная',
          cross: 'Кросс',
          positionInfo: 'Информация о позиции',
          markPrice: 'Цена маркировки',
          liquidationPrice: 'Цена ликвидации',
          status: 'Статус',
        },
      },
    },
  };

  return transaltion[type].panel;
};
